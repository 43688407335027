import BlockIcon from '@mui/icons-material/Block';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {Box, Button, Stack, Typography} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';

type ErrorType = '403' | '404' | '404-resource' | '500' | 'default';

interface ErrorConfig {
    icon: React.ReactNode;
    title: string;
    subtitle: string;
    actionText: string;
}

type ErrorConfigurations = {
    [key in ErrorType]: ErrorConfig;
};

function ErrorPage(
    {
        title,
        subtitle,
        error = undefined,
        type = '404'
    }: {
        title?: string;
        subtitle?: string;
        error?: unknown;
        type?: ErrorType;
    }
) {

    const navigate = useNavigate();

    const errorConfig: ErrorConfigurations = {
        '403': {
            icon: <BlockIcon sx={{fontSize: 96, color: 'error.main'}}/>,
            title: "Access Denied",
            subtitle: "Sorry, you don't have permission to access this page. Please contact your administrator if you believe this is a mistake.",
            actionText: "Return to Dashboard",
        },
        '404': {
            icon: <ErrorOutlineIcon sx={{fontSize: 96, color: 'primary.main'}}/>,
            title: "Page Not Found",
            subtitle: "We couldn't find the page you're looking for. It might have been moved or deleted.",
            actionText: "Go to Homepage",
        },
        '404-resource': {
            icon: <SearchOffIcon sx={{fontSize: 96, color: 'info.main'}}/>,
            title: "Resource Not Found",
            subtitle: "The requested resource could not be found. It may have been deleted or does not exist.",
            actionText: "Go Back",
        },
        '500': {
            icon: <CloudOffIcon sx={{fontSize: 96, color: 'warning.main'}}/>,
            title: "Server Error",
            subtitle: "We're experiencing some technical difficulties. Our team has been notified and is working on the issue. Please try again later.",
            actionText: "Refresh Page",
        },
        'default': {
            icon: <WarningAmberIcon sx={{fontSize: 96, color: 'warning.main'}}/>,
            title: title || "Error Occurred",
            subtitle: subtitle || "An unexpected error occurred. Please try again.",
            actionText: "Go Back",
        },
    };

    const config = errorConfig[type] || errorConfig.default;

    const handleAction = (): void => {
        navigate('/');
    };

    return (
        <Box sx={{
            minHeight: '70vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 4,
        }}>
            <Stack
                spacing={4}
                alignItems="center"
                maxWidth="lg">
                {/* Icon */}
                {config.icon}
                {/* Title */}
                <Typography
                    variant="h2"
                    component="h1"
                    textAlign="center"
                    fontWeight="bold"
                    color="text.primary">
                    {config.title}
                </Typography>
                {/* Subtitle */}
                <Typography
                    variant="h6"
                    textAlign="center"
                    color="text.secondary"
                    maxWidth="sm">
                    {config.subtitle}
                </Typography>
                {/* Action Button */}
                <Button
                    variant="contained"
                    size="large"
                    onClick={handleAction}
                    sx={{mt: 2}}>
                    {config.actionText}
                </Button>
            </Stack>
        </Box>
    );
}

export default ErrorPage;
